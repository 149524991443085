
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { observer, inject } from "mobx-react";

import Modal from '../components/modal';
import { fetch } from '../utils/api-wrapper';
import { useNavigate } from 'react-router-dom';

const Webhooks = inject("data")(observer(({ data }) => {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState(new URLSearchParams(window.location.search));
    const [showDisapproval, setShowDisapproval] = useState(params.get('action') ==='reject');
    const [causeDenial, setCauseDenial] = useState();
    const [invoice, setInvoice] = useState(null);

    const fetchInvoice = () =>  {
        setLoading(true);
        fetch("/invoices/partial/" + params.get('invoice_id'), "GET", {},
            response => {
                setInvoice(response.data.invoice);
                setLoading(false);
            },
            error => {
                setErrorMessage(data.t.read("error"));
                setLoading(false);
            });
    }

    useEffect(() => {
        if(invoice === null) return;
        params.get('action') ==='denied' && setShowDisapproval(true);
        params.get('action') ==='approved' && updateDetail('Approved', 'Approved');
    }, [invoice])

    useEffect(() =>{
                        window.localStorage.setItem("jwt", params.get('token'));
                        fetch("/refresh-token", "GET", {},
                                response => {
                                    data.username = response.data.username;
                                    data.firstName = response.data.first_name;
                                    data.lastName = response.data.last_name;
                                    data.origin_role = response.data.origin_role;
                                    data.unit = response.data.unit;
                                    data.role = response.data.role;
                                    data.type = response.data.type;
                                    data.password_expired = response.data.password_expired;
                                    data.config = response.data.config;
                                    data.client_name = response.data.client_name;
                                    data.id_client = response.data.id_client;
                                    data.expiration_date = response.data.expiration_date;
                                    window.localStorage.setItem("jwt", response.data.token);
                                    fetchInvoice();
                                },
                                error => {
                                    setErrorMessage('errors.invalid_credentials')
                                    window.localStorage.removeItem("jwt");
                                    navigate('/signin', { replace: true });
                                }
                            );
                    } 
    ,[]);

    const updateDetail = (status, causeDenial) => {
        const inv = invoice;
        setShowDisapproval(false);
        inv.json.ListItem = inv.json.ListItem.map(item => 
            item._view_id == params.get('_view_id') 
                ? { 
                    ...item,
                    Status: status,
                    status_details: `${Intl.DateTimeFormat(
                        `${data.t?.lang}-US`, 
                        { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }
                    ).format(new Date())} : ${data.firstName} ${data.lastName} : ${causeDenial}\n${item.status_details ?? ''}`
                }
                : item
        );
       updateInvoice(inv);
    }

    const updateInvoice = (inv) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("status", inv.status);
        formData.append("status_details", inv.status_details ?? "");

        if (inv.json != null) {
            let json = inv.json;

            if (json.ListItem != null) {
                json = JSON.parse(JSON.stringify(json));
            }

            formData.append("json", JSON.stringify(json));
        }

        fetch(`/invoices/${params.get('invoice_id')}`, "PUT", formData,
            response => {setLoading(false); setSuccessMessage(data.t.read(`status.${params.get('action')}`));},
            error => {
                        setErrorMessage(data.t.read("internal_saving_error"));
                        setLoading(false);
                     }
        );
        
    }

    return loading ? 
            <div className="loading-container">
                <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
            </div> :
            <>
            {
                errorMessage && 
                <Modal title='Approbation' okText={data.t.read('dashboard')} okAction={() => navigate('/dashboard')}>
                    {errorMessage}
                </Modal>
            }
            {
                successMessage && 
                    <Modal title='Approbation' okText={data.t.read('dashboard')} okAction={() => navigate('/dashboard')}>
                        {successMessage}
                    </Modal>
            }
            {
                showDisapproval &&
                    <Modal overflow="visible" title={data.t.read("denial_cause_placeholder")} cancelAction={() => setShowDisapproval(false)} okAction={() => updateDetail('Denied', causeDenial)} okText={data.t.read("submit")} cancelText={data.t.read("cancel")}>
                        <input className='form-control' type="text" onChange={e => setCauseDenial(e.target.value)} />
                    </Modal>
            }
            </>
}))

export default Webhooks;