import {Link} from "react-router-dom";
import React from "react";

const AdminNavigationBar = ({data, adminPage}) => {
    return (
            <div className="navbar mb-4">
                <div className="d-flex nav-tabs ">
                    <div
                        className={adminPage === "suppliers" ? "nav-item active custom-border-right" : "nav-item custom-border-right"}>
                        <Link to="/admin/suppliers" className=" nav-link">
                            <span>{data.t.read("suppliers")}</span>
                        </Link>
                    </div>
                    <div
                        className={adminPage === "gl_natural" ? "nav-item active custom-border-right" : "nav-item custom-border-right"}>
                        <Link to="/admin/gl_naturals" className="nav-link">
                            <span>{data.t.read("gl_codes")}</span>
                        </Link>
                    </div>
                    <div
                        className={adminPage === "users" ? "nav-item active custom-border-right" : "nav-item custom-border-right"}>
                        <Link to="/admin/users" className="nav-link">
                            <span>{data.t.read("users")}</span>
                        </Link>
                    </div>
                    <div
                        className={adminPage === "offices" ? "nav-item active custom-border-right" : "nav-item custom-border-right"}>
                        <Link to="/admin/offices" className=" nav-link">
                            <span>{data.t.read("offices")}</span>
                        </Link>
                    </div>
                    <div
                        className={adminPage === "clients" ? "nav-item active custom-border-right" : "nav-item custom-border-right"}>
                        <Link to="/admin/clients" className="nav-link">
                            <span>{data.t.read("clients")}</span>
                        </Link>
                    </div>
                    <div
                        className={adminPage === "notifications" ? "nav-item active " : "nav-item"}>
                        <Link to="/admin/notifications" className=" nav-link">
                            <span>{"Notifications"}</span>
                        </Link>
                    </div>
                </div>
            </div>
    );
};

export default AdminNavigationBar;
