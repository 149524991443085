import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Modal from './modal';

const Closemark = ({ data, isClosed, disabled, onClosed, callback }) => {
    const [showDisapproval, setShowDisapproval] = useState(false);
    const [causeDenial, setCauseDenial] = useState(null);
    const [loading, setLoading] = useState(false);
    const [closed, setClosed] = useState(isClosed);

    useEffect(() => {
        setClosed(isClosed)
      }, [isClosed]);


    const handleCheckboxChange = async () => {
        if (disabled) return; // Prevent changes if disabled
        
        if (onClosed) {
            setLoading(true);
            const result = await onClosed(causeDenial);
            setClosed(result);
            setLoading(false);
            if(callback){
                callback();
            } 
        }
        
    }

    return <div>
        { loading ?
             <FontAwesomeIcon className="text-secondary infinite-rotate faded" icon={faSync} />
            :
            <FontAwesomeIcon
            icon={faWindowClose}
            className={`pointer mr-2 ml-2 2xl ${closed ? 'text-danger' : 'text-secondary'}`}
            onClick={()=> !disabled && setShowDisapproval(true)}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        />}
        {
            showDisapproval && onClosed &&
            <Modal overflow="visible" title={data.t.read("denial_cause_placeholder")} cancelAction={() => setShowDisapproval(false)} okAction={() => { handleCheckboxChange(); setShowDisapproval(false) }} okText={data.t.read("submit")} cancelText={data.t.read("cancel")}>
                <input className='form-control' type="text" onChange={e => setCauseDenial(e.target.value)} />
            </Modal>
        }
    </div>

};

export default Closemark;
