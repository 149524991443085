import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faPlus, faSpinner, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/modal";
import Checkbox from "../../components/checkbox";
import {fetch} from "../../utils/api-wrapper";
import FilterInputField from "../../components/filter-input-field";
import PaginationControls from "../../components/pagination-controls";
import AdminNavigationBar from "../../components/admin-navigation-bar";
import Switch from "react-switch";
import Select from "react-select";

const AdminNotifications = ({data}) => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [updatedNotification, setUpdatedNotification] = useState({});
    const [notificationsDeleteSelection, setNotificationsDeleteSelection] = useState([]);
    const [showDeleteNotificationModal, setShowDeleteNotificationModal] = useState(false);

    const [pageSize, setPageSize] = useState(20);
    const [filterValue, setFilterValue] = useState("");
    const [sorting, setSorting] = useState([{"id": "names", "desc": false}]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [page, setPage] = useState(1);

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles, border: "0 !important", boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    }

    const DAYS_OF_WEEK = [{value: "mon", label: 'M'}, {value: 'tue', label: 'T'}, {
        value: 'wed',
        label: 'W'
    }, {value: 'thu', label: 'T'}, {value: 'fri', label: 'F'}, {value: 'sat', label: 'S'}, {value: 'sun', label: 'S'},];

    const timezones = [{value: "UTC", label: "UTC"}, {
        value: "America/Toronto",
        label: data.t.read("timezone.america_toronto")
    }, {value: "America/Vancouver", label: data.t.read("timezone.america_vancouver")}, {
        value: "America/New_York",
        label: data.t.read("timezone.america_new_york")
    }, {value: "America/Chicago", label: data.t.read("timezone.america_chicago")}, {
        value: "America/Denver",
        label: data.t.read("timezone.america_denver")
    }, {value: "America/Los_Angeles", label: data.t.read("timezone.america_los_angeles")}, {
        value: "Europe/London",
        label: data.t.read("timezone.europe_london")
    }, {value: "Europe/Paris", label: data.t.read("timezone.europe_paris")}, {
        value: "Europe/Berlin",
        label: data.t.read("timezone.europe_berlin")
    }, {value: "Europe/Rome", label: data.t.read("timezone.europe_rome")},];

    useEffect(() => {
        refreshNotifications();
    }, []);

    useEffect(() => {
        setPage(1);
        refreshNotifications();
    }, [pageSize, sorting, filterValue]);

    useEffect(() => {
        refreshNotifications();
    }, [page]);

    const refreshNotifications = () => {
        let start = (page - 1) * pageSize;
        const filters = [];
        if (filterValue && filterValue.trim() !== "") {
            filters.push({id: "search", value: filterValue.trim()});
        }
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(start)}&page_size=${pageSize}`;
        fetch(`/notifications/${searchData}`, "GET", {},

            response => {
                let fetchedNotifications = response.data.notifications;
                setNotifications([...fetchedNotifications]);
                setTotalRowCount(response.data.meta.totalRowCount)
                setLoading(false);
            });
    };

    const handleNotificationSubmit = (e) => {
        e.preventDefault();
        setShowNotificationModal(false);
        if (updatedNotification._id) {
            updateNotification();
        } else {
            createNotification();
        }
    };

    const createNotification = () => {
        let formData = new FormData();
        formData.append('notification', JSON.stringify(updatedNotification));

        fetch("/notifications/", "POST", formData, () => {
            setSuccessMessage(data.t.read("created_succesfully"));
            refreshNotifications();
        }, () => {
            alert("Error");
            setLoading(false);
        });
    };

    const updateNotification = (notification = {...updatedNotification}) => {
        let updatedNotificationWithoutId = {...notification}
        delete updatedNotificationWithoutId._id;
        let formData = new FormData();
        formData.append('notification', JSON.stringify(updatedNotificationWithoutId));
        const notificationId = notification._id?.$oid
        setSaving(true)
        fetch(`/notifications/${notificationId}`, "PUT", formData, () => {
            refreshNotifications();
            setSuccessMessage(data.t.read("notification_updated_successfully"))
            setSaving(false)
        }, () => {
            setErrorMessage(data.t.read("failed_to_update_notification"))
            setLoading(false);
            setSaving(false)
        });
    };

    const openNewNotificationModal = () => {
        setUpdatedNotification({
            name: "", id_client: data.id_client, type: "invoice_volume", frequency: {
                type: "monthly", interval: {
                    second: 0, minute: 0, day_of_the_month: 1, time: "09:00", timezone: "America/Toronto"
                }
            }, recipients: [], description: "", active: true,
        });
        setShowNotificationModal(true);
    };

    const openUpdateNotificationModal = (notification) => {
        setUpdatedNotification(notification);
        setShowNotificationModal(true);
    };

    const cancelNotificationModal = () => {
        setUpdatedNotification({});
        setShowNotificationModal(false);
    };

    const deleteNotifications = () => {
        fetch("/notifications/", "DELETE", {"notification_ids": notificationsDeleteSelection}, () => {
            refreshNotifications();
            setNotificationsDeleteSelection([])
            setShowDeleteNotificationModal(false)
        }, (error) => {
            setErrorMessage(data.t.read("failed_to_delete"))
            setLoading(false);
            setShowDeleteNotificationModal(false)
        });
    };

    const cancelDeleteNotificationModal = () => {
        setShowDeleteNotificationModal(false);
        setNotificationsDeleteSelection([]);
    };

    const isAllNotificationsSelected = () => {
        if (!notifications || !notifications.length) {
            return false;
        }
        return notifications.every(n => notificationsDeleteSelection.includes(n._id?.$oid));
    };

    const isNotificationSelected = (notification) => {
        return notificationsDeleteSelection.includes(notification._id?.$oid);
    };

    const toggleAllNotificationsSelected = () => {
        setNotificationsDeleteSelection(isAllNotificationsSelected() ? notificationsDeleteSelection.filter(id => !notifications.some(notification => notification._id?.$oid === id)) : [...notificationsDeleteSelection, ...notifications.map((notification) => notification._id?.$oid).filter((id) => !notificationsDeleteSelection.includes(id))]);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (rows) => {
        setPage(1)
        setPageSize(rows)
    }

    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + notifications.length - 1;
    const totalPages = Math.ceil(totalRowCount / pageSize);
    const emptyRowsCount = Math.max(0, pageSize - notifications.length);
    const emptyRows = Array(emptyRowsCount).fill({});

    return (<div className="card pl-4 pr-4 pb-3 rounded-lg">
            <AdminNavigationBar data={data} adminPage={"notifications"}></AdminNavigationBar>
            <div>
                {!loading ? <div>
                    <div>
                        <div className='pagination-row '>
                            <FilterInputField
                                filterValue={filterValue} setFilterValue={setFilterValue} placeholder={""}>
                            </FilterInputField>
                            <PaginationControls
                                page={page}
                                totalPages={totalPages}
                                indexOfFirstItem={indexOfFirstItem}
                                indexOfLastItem={indexOfLastItem}
                                handlePageChange={handlePageChange}
                                totalItems={totalRowCount}
                                data={data}
                                handleRowsPerPageChange={handleRowsPerPageChange}
                                rowsPerPage={pageSize}
                            ></PaginationControls>
                        </div>
                        <div className="d-flex justify-content-end align-items-center py-2">
                            <div className="d-flex">
                                <button
                                    disabled={notificationsDeleteSelection.length === 0}
                                    onClick={() => setShowDeleteNotificationModal(true)}
                                    type="button"
                                    className="btn btn-secondary btn-sm ml-2">
                                    {data.t.read("delete") + " (" + notificationsDeleteSelection.length + ")"}
                                </button>
                                <button
                                    onClick={() => openNewNotificationModal()}
                                    type="button"
                                    className='btn btn-primary btn-sm ml-2'>
                                    {data.t.read("add")}
                                </button>
                            </div>
                        </div>
                        <div className="mb-2 card rounded-lg admin-table">
                            <table className='table table-hover font-small'>
                                <thead>
                                <tr>
                                    <th className="" key={`checkbox`}>
                                        <Checkbox
                                            disabled={!notifications || !notifications.length}
                                            isChecked={isAllNotificationsSelected()}
                                            onChange={toggleAllNotificationsSelected}/>
                                    </th>
                                    <th key={"name"}>{data.t.read("name")}</th>
                                    <th key={"description"}>{data.t.read("description") || "Description"}</th>
                                    <th key={"type"}>{data.t.read("type") || "Type"}</th>
                                    <th key={"frequency"}>{data.t.read("frequency") || "Frequency"}</th>
                                    <th key={"recipients"}>{data.t.read("recipients") || "Recipients"}</th>
                                    <th className={"text-center"} key={"active"}>{data.t.read("active") || "Active"}</th>
                                    <th className={"text-center"} key={"actions"}>{data.t.read("actions") || "Actions"}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {notifications.map((notification) => {
                                    return <tr key={notification._id}>
                                        <td>
                                            <Checkbox
                                                isChecked={isNotificationSelected(notification)}
                                                onChange={() => isNotificationSelected(notification) ? setNotificationsDeleteSelection(notificationsDeleteSelection.filter(x => x !== notification._id?.$oid)) : setNotificationsDeleteSelection([...notificationsDeleteSelection, notification._id?.$oid])}/>
                                        </td>
                                        <td className={""}
                                            onClick={() => openUpdateNotificationModal(notification)}>
                                            {notification.name}
                                        </td>
                                        <td className={"col-2"}
                                            onClick={() => openUpdateNotificationModal(notification)}>
                                            {notification.description}
                                        </td>
                                        <td className={""}
                                            onClick={() => openUpdateNotificationModal(notification)}>
                                            {notification.type}
                                        </td>
                                        <td className={""}
                                            onClick={() => openUpdateNotificationModal(notification)}>
                                            {notification.frequency.type}
                                        </td>
                                        <td className={"col-2"}
                                            onClick={() => openUpdateNotificationModal(notification)}>
                                                <span className="comments-column">
                                                    {notification.recipients ? notification.recipients.join('\n ') : ''}
                                                </span>
                                        </td>
                                        <td className="">
                                            <div className=" d-flex justify-content-center align-items-center">
                                                <Switch
                                                    className='switch mt-0  '
                                                    checked={notification.active}
                                                    disabled={saving}
                                                    width={70}
                                                    onColor="#F39800"
                                                    onHandleColor="#2693e6"
                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    onChange={(active) => updateNotification({
                                                        ...notification, active: active
                                                    })}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className=" d-flex justify-content-center align-items-center">

                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faPencilAlt}
                                                    type="button"
                                                    onClick={() => openUpdateNotificationModal(notification)}
                                                />
                                                <FontAwesomeIcon
                                                    onClick={() => {
                                                        setShowDeleteNotificationModal(true);
                                                        setNotificationsDeleteSelection([notification._id?.$oid]);
                                                    }}
                                                    icon={faWindowClose}
                                                    type="button"
                                                    style={{color: 'red'}}/>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                                {
                                    emptyRows.map((_, idx) => (<tr key={`empty-${idx}`}>
                                        <td colSpan="8">&nbsp;</td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {errorMessage != null && (<Modal title={data.t.read("attention")} okText={"OK"}
                                                     okAction={() => setErrorMessage(null)}>
                            <p style={{whiteSpace: 'pre-line'}} className='text-danger'>{errorMessage}</p>
                        </Modal>)}
                    {successMessage != null && (<Modal title={data.t.read("attention")} okText={"OK"}
                                                       okAction={() => setSuccessMessage(null)}>
                            {successMessage}
                        </Modal>)}
                    {showNotificationModal && (<form method="post" onSubmit={handleNotificationSubmit}>
                            <Modal
                                title={"Notification"}
                                okIsSubmit={true}
                                cancelAction={cancelNotificationModal}
                                okText={data.t.read("save")}
                                width="640px"
                                cancelText={data.t.read("cancel")}>

                                <div className='form-group'>
                                    <h5>
                                        {data.t.read("name")}:
                                    </h5>
                                    <input
                                        required
                                        placeholder={data.t.read("name")}
                                        type={"text"}
                                        className="form-control "
                                        name="name"
                                        value={updatedNotification.name}
                                        onChange={(e) => setUpdatedNotification({
                                            ...updatedNotification, name: e.target.value
                                        })}
                                    />
                                </div>
                                <div className='form-group'>
                                    <h5>
                                        {"Description"}:
                                    </h5>
                                    <textarea
                                        required
                                        placeholder={data.t.read("description")}
                                        className="form-control "
                                        name="description"
                                        value={updatedNotification.description}
                                        onChange={(e) => setUpdatedNotification({
                                            ...updatedNotification, description: e.target.value
                                        })}
                                    />
                                </div>
                                <div className='form-group'>
                                    <h5>
                                        {"Type"}:
                                    </h5>
                                    <select className="form-control"
                                            value={updatedNotification.type}
                                            onChange={(e) => setUpdatedNotification({
                                                ...updatedNotification, type: e.target.value

                                            })}>
                                        <option value="invoice_volume">{data.t.read("invoice_volume")}</option>
                                    </select>
                                </div>
                                <div className='form-group'>
                                    <h5>
                                        {data.t.read("frequency")}:
                                    </h5>

                                    <div
                                        className={"d-flex text-nowrap justify-content-start align-items-center mt-2 "}
                                    >

                                        <span className={"mr-2 col-2"}>{data.t.read("every")}</span>
                                        <select className="form-control"
                                                value={updatedNotification.frequency.type}
                                                onChange={(e) => setUpdatedNotification({
                                                    ...updatedNotification, frequency: {
                                                        ...updatedNotification.frequency, type: e.target.value
                                                    }
                                                })}>
                                            <option value="daily">{data.t.read("daily")}</option>
                                            <option value="weekly">{data.t.read("weekly")}</option>
                                            <option value="monthly">{data.t.read("monthly")}</option>
                                            <option value="yearly">{data.t.read("yearly")}</option>
                                        </select>
                                    </div>
                                    <div
                                        className={"d-flex text-nowrap justify-content-start align-items-center mt-2 "}
                                    >
                                        {updatedNotification.frequency.type === "monthly" && <>
                                            <span className={"mr-2 col-2"}>{data.t.read("on_day")}</span>
                                            <input
                                                required
                                                placeholder={data.t.read("day")}
                                                type={"number"}
                                                className="form-control "
                                                name="day"
                                                min="1"
                                                max="31"
                                                value={updatedNotification.frequency.interval.day_of_the_month}
                                                onChange={(e) => setUpdatedNotification({
                                                    ...updatedNotification, frequency: {
                                                        ...updatedNotification.frequency, interval: {
                                                            ...updatedNotification.frequency.interval,
                                                            day_of_the_month: e.target.value
                                                        }
                                                    }
                                                })}
                                            />
                                        </>}
                                        {updatedNotification.frequency.type === "weekly" && (<>
                                                <span className="mr-2 col-2">{data.t.read("daily")}s</span>
                                                <div className="d-flex">
                                                    {DAYS_OF_WEEK.map((day) => {
                                                        const isSelected = updatedNotification.frequency.interval.day_of_week?.includes(day.value);

                                                        const toggleDay = () => {
                                                            const existing = updatedNotification.frequency.interval.day_of_week || [];
                                                            let newDays;
                                                            if (isSelected) {
                                                                newDays = existing.filter((d) => d !== day.value);
                                                            } else {
                                                                newDays = [...existing, day.value];
                                                            }
                                                            setUpdatedNotification({
                                                                ...updatedNotification, frequency: {
                                                                    ...updatedNotification.frequency, interval: {
                                                                        ...updatedNotification.frequency.interval,
                                                                        day_of_week: newDays
                                                                    }
                                                                }
                                                            });
                                                        };
                                                        return (<button
                                                                key={day.value}
                                                                type="button"
                                                                onClick={toggleDay}
                                                                className={`day-btn ${isSelected ? "day-btn-selected" : ""}`}
                                                            >
                                                                {day.label}
                                                            </button>);
                                                    })}
                                                </div>
                                            </>)}
                                        {updatedNotification.frequency.type === "yearly" && <>
                                            <span className={"mr-2 col-2"}>{data.t.read("on_day")}</span>
                                            <input
                                                required
                                                placeholder={data.t.read("day")}
                                                type={"date"}
                                                className="form-control "
                                                value={updatedNotification.frequency.interval?.date || ""}
                                                onChange={(e) => {
                                                    const dateStr = e.target.value;
                                                    const [year, month, day] = dateStr.split('-').map(n => parseInt(n, 10));

                                                    setUpdatedNotification({
                                                        ...updatedNotification, frequency: {
                                                            ...updatedNotification.frequency, interval: {
                                                                ...(updatedNotification.frequency.interval || {}),
                                                                year: year,
                                                                month: month,
                                                                day_of_month: day,
                                                                date: e.target.value,
                                                            }
                                                        }
                                                    });
                                                }}
                                            />
                                        </>}
                                    </div>
                                    <div
                                        className={"d-flex text-nowrap justify-content-start align-items-center mt-2 "}
                                    >

                                        <span className={"mr-2 col-2"}>{data.t.read("time")}</span>
                                        <input
                                            required
                                            placeholder={data.t.read("time")}
                                            type={"time"}
                                            className="form-control col-4"
                                            value={updatedNotification.frequency.interval?.time}
                                            onChange={(e) => {
                                                const timeStr = e.target.value;
                                                const [hour, minute] = timeStr.split(':').map(n => parseInt(n, 10));

                                                setUpdatedNotification({
                                                    ...updatedNotification, frequency: {
                                                        ...updatedNotification.frequency, interval: {
                                                            ...(updatedNotification.frequency.interval || {}),
                                                            hour: hour,
                                                            minute: minute,
                                                            time: e.target.value,
                                                        }
                                                    }
                                                });
                                            }}
                                        />
                                        <Select
                                            styles={styleSelect}
                                            name="timezone select"
                                            value={timezones.find(option => option.value === updatedNotification.frequency.interval?.timezone)}
                                            options={timezones}
                                            className="col-6"
                                            classNamePrefix="select "
                                            onChange={(choice) => {
                                                setUpdatedNotification({
                                                    ...updatedNotification, frequency: {
                                                        ...updatedNotification.frequency, interval: {
                                                            ...(updatedNotification.frequency.interval || {}),
                                                            timezone: choice.value,
                                                        }
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <h5>
                                        {data.t.read("recipients")}:
                                        <button className='btn btn-primary btn-xs ml-2 pl-1 pr-1' type="button"
                                                onClick={() => setUpdatedNotification({
                                                    ...updatedNotification,
                                                    recipients: [...updatedNotification.recipients, ""]
                                                })}
                                        >
                                            <FontAwesomeIcon icon={faPlus}/>
                                        </button>
                                    </h5>
                                    {updatedNotification.recipients.map((recipient, i) => (<div key={i}
                                                                                                className="admin-row d-flex justify-content-between form-control align-items-center mb-2 p-2">
                                        <input
                                            required
                                            placeholder={data.t.read("email")}
                                            type={"email"}
                                            className="form-control border-0" name="recipient email"
                                            style={{backgroundColor: 'transparent'}}
                                            value={recipient}
                                            onChange={(e) => setUpdatedNotification({
                                                ...updatedNotification,
                                                recipients: updatedNotification.recipients.map((item, index) => index === i ? e.target.value : item)
                                            })}
                                        />
                                        <FontAwesomeIcon className="ml-2 align-content-center item-actions"
                                                         icon={faWindowClose} type="button" color={"red"}
                                                         onClick={() => setUpdatedNotification({
                                                             ...updatedNotification,
                                                             recipients: updatedNotification.recipients.filter((x, index) => index !== i)
                                                         })}
                                        />
                                    </div>))}
                                </div>
                                <div className='form-group'>
                                    <h5>
                                        {data.t.read("active")}:
                                    </h5>
                                    <Switch
                                        className='switch d-block mt-0 ml-2'
                                        checked={updatedNotification.active}
                                        width={70}
                                        onColor="#F39800"
                                        onHandleColor="#2693e6"
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(active) => setUpdatedNotification({
                                            ...updatedNotification, active: active
                                        })}
                                    />
                                </div>
                            </Modal>
                        </form>)}
                    {showDeleteNotificationModal && (<Modal
                            title={data.t.read("delete") || "Delete"}
                            okAction={deleteNotifications}
                            cancelAction={cancelDeleteNotificationModal}
                            okText={data.t.read("delete") || "Delete"}
                            cancelText={data.t.read("cancel") || "Cancel"}>
                            {data.t.read("delete_notification_confirmation") || "Are you sure you want to delete the selected notifications?"}
                        </Modal>)}
                </div> : <div className="d-flex loading-container w-100">
                    <FontAwesomeIcon className="infinite-rotate" icon={faSpinner}/>
                </div>}
            </div>
        </div>);
};

export default inject('data')(observer(AdminNotifications));